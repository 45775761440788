import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Reveal from '../reveal'

class ProjectShortDescription extends Component {
  render() {
    const { title, description, text, className, fixedTitleWidth } = this.props
    return (
      <Reveal>
        <div className={`container cs-project-short-description ${className}`}>
          <div className={'col-md-6'}>
            <div className={`${fixedTitleWidth ? 'fixWidth' : ''}`}>
              <h2 className="maxWidthIndicator">{title}</h2>
              <p className="description" dangerouslySetInnerHTML={{ __html: description }}></p>
              <p className="description-smaller" dangerouslySetInnerHTML={{ __html: text }}></p>
            </div>
          </div>
        </div>
      </Reveal>
    )
  }
}

ProjectShortDescription.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

ProjectShortDescription.defaultProps = {
  fixedTitleWidth: false
}


export default ProjectShortDescription