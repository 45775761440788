import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Reveal from '../reveal'

class ColorShowcase extends Component {
  render() {
    const { colors, useTwoRows } = this.props

    let width = 100 / colors.length;
    if (useTwoRows) {
      width *= 2;
    }
    width -= 1;
    width += '%';
    return (
      <Reveal>
        <div className={'cs-color-showcase container mb-3'}>
          <div className={'row colors'}>
            {colors.map((color, i) => {
              const border = (color.code.toLowerCase() === '#ffffff') ? '1px solid #DBDBDB' : 'none'
              return (
                <div className={`col color ${colors.length === 4 ? 'fix-xs' : ''}`} style={{ backgroundColor: color.code, border, minWidth: width }} key={i}>
                  <div className={'name-wrapper'}>
                    <div className={'name'} style={{ color: color.nameColor }}>{color.name}</div>
                    {color.tintColorCode && (
                      <div className={'tint'} style={{ backgroundColor: color.tintColorCode, borderTop: border }} />
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </Reveal>
    )
  }
}

ColorShowcase.propTypes = {
  colors: PropTypes.array.isRequired,
}

export default ColorShowcase