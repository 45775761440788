import React, { Component } from 'react'
import { getTranslateFunction } from '../../utilities'
import { TranslatedLink } from '../TranslatedLink'
import LinkBtn from '../linkBtn/LinkBtn'
import * as PropTypes from 'prop-types'
import ContaktUs from '../contactUs/ContactUs'

class Footer extends Component {
  render() {
    const { nextProjectName, nextProjectUrl, leftTopTitle, secondLink, title, subtitle, link } = this.props
    const t = getTranslateFunction('sk') //TODO: Lang
    return (
      <>
        <section className="casestudy-block cat-block about-us-projects cs-footer">
          <div className="container">
            <h3>{t(title)}</h3>
            {secondLink &&
              <LinkBtn color={'silver'}>
                <TranslatedLink to={secondLink.to}>
                  {secondLink.text}
                </TranslatedLink>
              </LinkBtn>
            }
            <LinkBtn>
              <TranslatedLink to={link}>{t(subtitle)}</TranslatedLink>
            </LinkBtn>
          </div>
        </section>
        <ContaktUs
          LeftSideTitle={leftTopTitle}
          LeftSideSubTitle={nextProjectName}
          LeftSideLink={nextProjectUrl}
          linkRight="/en/contact"
          rightSecondLink="/en/brief"
        />
      </>
    )
  }
}

Footer.propTypes = {
  nextProjectName: PropTypes.string.isRequired,
  nextProjectUrl: PropTypes.string.isRequired,
  secondLink: PropTypes.shape({
    text: PropTypes.string,
    to: PropTypes.string,
  }),
}
Footer.defaultProps = {
  title: 'OurServices.Subtitle',
  subtitle: 'OurServices.AllReferences',
  link: 'en/our-works/featured',
}

export default Footer


