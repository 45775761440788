import React, { useEffect, useRef, useState } from 'react'
import Reveal from '../reveal'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const MobileCarousel = ({ items, icon, className, title, description, smallDescription }) => {
  const activeItemRef = useRef(null)
  const phoneRef = useRef(null)
  const lastTouchY = useRef(0)

  const data = useStaticQuery(graphql`
      query MobileCarousel {
          phone: file(relativePath: { eq: "case-studies/mobile_new.png" }) {
              childImageSharp {
                  fluid( maxWidth: 900) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          },
      }
  `)
  /* 
    scrollIndicator: file(relativePath: { eq: "case-studies/scroll.png" }) {
        childImageSharp {
            fluid( maxWidth: 125) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
  */

  useEffect(() => {
    if (phoneRef.current) {

      phoneRef.current.addEventListener('wheel', (e) => {
        const { current } = activeItemRef
        if (current) {
          if (e.deltaY < 0 && current.scrollTop === 0) {
            return true
          }
          if (e.deltaY > 0 && current.scrollHeight - current.scrollTop - current.clientHeight === 0) return true
          e.preventDefault()
          current.scrollBy(0, e.deltaY)
        }
        return false
      }, {passive: true})

      phoneRef.current.addEventListener('touchstart', (e) => {
        lastTouchY.current = e.touches[0].clientY

        return true;
      }, {passive: true})

      phoneRef.current.addEventListener('touchmove', (e) => {
        return  true;
      /*   const deltaY = e.touches[0].clientY - lastTouchY.current
        lastTouchY.current = e.touches[0].clientY
        const { current } = activeItemRef

        if (deltaY > 0 && current.scrollTop === 0) return true;
        if (deltaY < 0 && current.scrollHeight - current.scrollTop - current.clientHeight === 0) return true;

        if (current) {
        
          current.scrollBy(0, deltaY * -1)
        } */
      }, {passive: true})
    }
  }, [])

  const [index, setIndex] = useState(0)

  const next = () => {
    setIndex((i) => Math.min(i + 1, items.length - 1))
  }

  const prev = () => {
    setIndex((i) => Math.max(i - 1, 0))
  }

  return (
    <div className={`dark-section mobile-carousel wrapper ${className}`}>
      <Reveal>
        <div className={`container ${icon != null ? 'pb-md-5' : ''}`}>
          <div className={'row'}>
            <div className={icon == null ? 'col-md-8 push-md-2' : 'col-md-7'}>
              <div>
                <h2 className={'cs-title'} >{title}</h2>
                <p className="description" dangerouslySetInnerHTML={{ __html: description }}></p>
                <p className="description-smaller" dangerouslySetInnerHTML={{ __html: smallDescription }}></p>
              </div>
            </div>
            {icon != null && <div className={'col-md-5'}>
              <Img alt={'Mobile icon'} fluid={icon.childImageSharp.fluid}/>
            </div>}
          </div>
        </div>
        <div className="slider">
          <div className={'scroll-indicator'}>
            <svg width="78" height="54" viewBox="0 0 78 54" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect opacity="0.2" x="28" y="4" width="22" height="46" rx="2" fill="#014EFF"/>
              <rect opacity="0.2" y="4" width="22" height="46" rx="2" fill="#014EFF"/>
              <rect opacity="0.2" x="56" y="4" width="22" height="46" rx="2" fill="#014EFF"/>
              <path d="M53 37.6526V49.563C53 51.4612 51.4558 53 49.5509 53H28.4491C26.5442 53 25 51.4612 25 49.563V4.43695C25 2.53878 26.5442 1 28.4491 1H49.5509C51.4558 1 53 2.53878 53 4.43695V9.91924V9.91924" stroke="#014EFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="0.51"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M61.0626 13.778C60.7355 14.1092 60.4921 14.5145 60.3529 14.9599C59.1418 13.7527 57.2187 13.7158 56.0602 14.8816C55.7325 15.2123 55.4891 15.6178 55.3505 16.0635C54.1394 14.8516 52.2139 14.8148 51.0555 15.9829C50.7273 16.3109 50.483 16.7141 50.3435 17.1579L44.5969 11.375C43.3996 10.1701 41.481 10.0894 40.3203 11.193C39.7447 11.7357 39.4139 12.4913 39.4045 13.285C39.3951 14.1486 39.7328 14.9794 40.3409 15.5889L53.1618 28.4908L49.4987 27.9494C47.4389 27.6072 45.4555 28.8878 44.906 30.9145C44.6795 31.7883 45.1801 32.686 46.0393 32.9466C48.8484 33.7967 55.7397 35.8219 59.1601 36.2642C59.6661 36.3564 65.2249 37.2687 69.252 33.2161C71.4842 30.9698 72.5694 28.2766 72.3839 25.4312C72.2219 23.2737 71.3951 21.2209 70.019 19.5585L65.4217 13.9301C65.4054 13.909 65.3878 13.889 65.3691 13.8702C64.158 12.6514 62.2257 12.6122 61.0626 13.7803" fill="#014EFF"/>
            </svg>

          </div>
          <div className="phone" ref={phoneRef}>
            <Img fluid={data.phone.childImageSharp.fluid} alt={'phone mockup'} className={'phone'}/>
          </div>
          <div className={'buttons'}>
            <button onClick={() => prev()}>
              <i className="la la-angle-left"></i>
              <svg viewBox="0 0 44 44" className="circle-animation">
                <circle className="back" cx="-22" cy="22" r="22" transform="rotate(-90)"></circle>
                <circle className="outer" cx="-22" cy="22" r="22" transform="rotate(-90)"></circle>
              </svg>
            </button>

            <button onClick={() => next()}>
              <i className="la la-angle-right"></i>
              <svg viewBox="0 0 44 44" className="circle-animation">
                <circle className="back" cx="-22" cy="22" r="22" transform="rotate(-90)"></circle>
                <circle className="outer" cx="-22" cy="22" r="22" transform="rotate(-90)"></circle>
              </svg>
            </button>
          </div>
          <div className="items" style={{ transform: `translateX(calc(${index * -1} * var(--item-offset)))` }}>
            {items.map((item, i) => {
              return (
                <div className="item-wrapper" key={i}>
                {/*   <div className={'top-bar'}>
                    <Img fluid={data.topBar.childImageSharp.fluid} alt={'screen'}/>
                  </div> */}
                  <div className={`item ${i === index ? 'active' : ''}`} ref={i === index ? activeItemRef : undefined}>
                    <Img fluid={item.childImageSharp.fluid} alt={'screen'} className={'item-img'}/>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </Reveal>
    </div>
  )
}

MobileCarousel.defaultProps = {
  className: 'dark',
  title: 'Mobilná verzia',
  description: 'Väčšina návštevníkov prichádza cez mobilné zariadenia. Nazabúdajte na to.',
  smallDescription: 'Responzívna verzia projektu je pre nás viac ako dôležitá. Stránky preto navrhujeme od samého začiatku tak, aby boli prehľadné a použiteľné aj na mobiloch, tabletoch či iných rozlíšeniach. Len vďaka tomu zabezpečíte svojim návštevníkom plnohodnotné <a href="/ux-ui-dizajn">UX (user experience)</a>, a zvýšite svoju šancu, aby využili Vaše služby, alebo zakúpili Váš produkt, či vykonali inú požadovanú akciu. Mobilné verzie dôkladne testujeme a simulujeme rôzne podmienky azariadenia, aby sme zaručili čo najlepší zážitok pri prezeraní Vášho projektu.'
}
export default MobileCarousel
