import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import VisibilitySensor from 'react-visibility-sensor'
import { scrollIntoView } from '../../utilities'
import Reveal from '../reveal'

class PagesShowcase extends Component {
  constructor(props) {
    super(props)
    this.ref = React.createRef()

    this.state = {
      currentPageIndex: 0,
    }
    this.onVisibilityChange = this.onVisibilityChange.bind(this)
    this.updateMenu = this.updateMenu.bind(this)
  }

  changePageIndex(i, scroll = true) {
    document.getElementById('imgs').style.transform = "translateY(0px)"
    this.setState({
        currentPageIndex: i,
      },
    )
    if (scroll) {
      setTimeout(() => {
          if (this.ref != null) {
            scrollIntoView(this.ref.current, false)
          }
        }, 250,
      )
    }
  }

  updateMenu(isMenuVisible) {
 
    if (typeof window !== 'undefined' && window.CASE_STUDY_PAGE_SHOWCASE_MOBILE_MENU != null) {
      const categories = this.props.pages.map((page) => {
        return { label: page.name, value: page.name }
      })
      const activeCategory = categories[this.state.currentPageIndex]
      const onChangeCallback = this.changePageIndex.bind(this)
      window.CASE_STUDY_PAGE_SHOWCASE_MOBILE_MENU(categories, activeCategory, onChangeCallback, isMenuVisible)
    }
  }

  onVisibilityChange(val) {
    this.updateMenu(val)
  }

  handleScroll(e) {
    let showCastCont = document.getElementById('cs-pages-showcase-cont')
    let imgCont = document.getElementById('imgs');
 
    let contHeight = imgCont.getBoundingClientRect().height - window.innerHeight / 2
    let addY = e.deltaY > 0 ? 50 : - 50
    
    let animation
    let top = 1000;
    let running = false

    if (!running) {
      running = true
      scroll();
    }
  
    function scroll() {
     
      let menu = e.target.closest('#left-menu');
      if (menu) {
        let hasScrollbar = menu.scrollHeight > menu.clientHeight;
        if(hasScrollbar) return
      }

      if (running) {
        animation = requestAnimationFrame(scroll);
        const target = showCastCont
        const rect = target.getBoundingClientRect()
        if (top === rect.top) {
          cancelAnimationFrame(animation);
        }

        top = rect.top
        const transformStyle = imgCont.style.transform.replace(/[^\d.]/g, '') || 0
        let position = parseInt(transformStyle) + addY;

  
        if (addY > 0 && position > contHeight) {
          return
        }

        if (addY < 0 && position < 0) {
          return
        }


        if((top <= 500 && top > -500)){
            e.preventDefault()
            showCastCont.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }

        if((top <= 50 && top > -50)){
            e.preventDefault()
            showCastCont.scrollIntoView({ behavior: 'auto', block: 'start' })
        }
        if(top > -10 && top < 10){
          imgCont.style.transform = "translateY(" + -position + "px)"
        }  
      }

    } 
   
  }

  componentDidMount() {
    if(window.innerWidth > 1200){
      window.addEventListener('mousewheel', this.handleScroll, { passive: true }); 
    }
  }

  componentWillUnmount() {
    window.removeEventListener('mousewheel', this.handleScroll, { passive: true }); 
  }


  render() {
    const { title, description, text, pages, itemsTitle, className, fixedTitleWidth } = this.props

    let ScrollTriggerEl = VisibilitySensor
    if (typeof window === 'undefined') {
      ScrollTriggerEl = (props) => (<div {...props}>{props.children}</div>)
    }

    return (
      <Reveal id="cs-pages-showcase-cont">
        <ScrollTriggerEl
          partialVisibility={true}
          minTopValue={500}
          offset={{top:500}}
          onChange={this.onVisibilityChange}>
          <div className={`cs-pages-showcase ${className}`} ref={this.ref}>
            <div className={'container'}>
              <div className={'row'}>
                <div className={'col-md-6'}>
                  <div
                    className={`${fixedTitleWidth ? 'fixWidth' : ''}`}
                    /* className={'sticky-wrapper'} */
                    id="left-menu">
                    <h2 className="maxWidthIndicator">{title}</h2>
                    <div>
                      <p className="description" dangerouslySetInnerHTML={{ __html: description }}></p>
                      <p className="description-smaller" dangerouslySetInnerHTML={{ __html: text }}></p>
                    </div>
                    {
                      pages.length > 1 ? (<div className={'menu'}>
                        <div>
                          <span className={'menu-title'}>{itemsTitle ? itemsTitle : 'Niektoré vybrané podstránky'}</span>
                          <ul>
                            {pages.map((page, i) => (
                              <li
                                key={i}
                                className={this.state.currentPageIndex === i ? 'active' : ''}
                                onClick={() => {
                                  this.changePageIndex(i)
                                }}>{page.name}</li>
                            ))}
                          </ul>
                        </div>
                       {/*  <LinkBtn>
                          <TranslatedLink to={projectDetail}>
                            Prejsť do detailu projektu
                          </TranslatedLink>
                        </LinkBtn> */}
                      </div>)
                        : (
                          <></>
                       /*  <div style={{ marginTop: '2rem' }}>
                          <LinkBtn>
                            <TranslatedLink to={projectDetail}>
                              Prejsť do detailu projektu
                            </TranslatedLink>
                          </LinkBtn>
                        </div> */
                      )
                    }
                  </div>
                </div>
                <div className={'col-md-6 image-wrapper'}>
                  <div id="imgs">
                    {pages.map((page, i) => (
                      <Img
                        key={i}
                        className={'img ' + ((this.state.currentPageIndex === i) ? 'active' : '')}
                        alt={page.name}
                        fluid={page.image.childImageSharp.fluid}/>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ScrollTriggerEl>
      </Reveal>
    )
  }
}

PagesShowcase.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  pages: PropTypes.array.isRequired,
  projectDetail: PropTypes.string.isRequired,
}

PagesShowcase.defaultProps = {
  fixedTitleWidth: false
}

export default PagesShowcase
