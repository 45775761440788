import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Reveal from '../reveal'

class AboutProject extends Component {
  render() {
    const { title, text, list } = this.props
    return (
      <Reveal>
        <div className={'container cs-about-project mb-0 mb-md-5'}>
          <div className="row">
            <div className={'col-md-12 text-center mb-5'}>
              <h2>{ title }</h2>
              <p className={'description'} dangerouslySetInnerHTML={{ __html: text }}></p>
            </div>
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className={'row'}>
                  {list.map((item, i) => (
                    <div className="align-items-center align-items-md-start col-md-6 d-flex flex-column flex-md-row  mt-5 row flex-nowrap flex-lg-wrap mr-0 ml-0" key={i}>
                      <div className={'col-1 number'}>
                        {i + 1}
                      </div>
                      <div className={'col-12 col-md-11'}>
                        <h3 dangerouslySetInnerHTML={{ __html: item.title }}></h3>
                        <p dangerouslySetInnerHTML={{ __html: item.text }}></p>
                      </div>
                    </div>
                  ))}
                  </div>
                </div>
            </div>

          </div>
        </div>
      </Reveal>
    )
  }
}

AboutProject.propTypes = {
  list: PropTypes.array.isRequired,
  text: PropTypes.string.isRequired,
}
AboutProject.defaultProps = {
  title: 'O projekte'
}


export default AboutProject