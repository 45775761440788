import React, { Component } from 'react'
import Landing from '../../../../../components/case-studies/Landing'
import AboutProject from '../../../../../components/case-studies/AboutProject'
import PagesShowcase from '../../../../../components/case-studies/PagesShowcase'
import Img from 'gatsby-image'
import Footer from '../../../../../components/case-studies/Footer'
import Layout from '../../../../../components/layout'
import { removeNumberPrefix } from '../../../../../utilities'
import ProjectShortDescription from '../../../../../components/case-studies/ProjectShortDescription'
import Reveal from '../../../../../components/reveal'
import ColorShowcase from '../../../../../components/case-studies/ColorShowcase'
import FontShowcase from '../../../../../components/case-studies/FontShowcase'
import MobileCarousel from '../../../../../components/case-studies/MobileCarousel'
import { graphql } from 'gatsby'
import ProjectImageLightBox from '../../../../../components/case-studies/ProjectImageLightBox'
import Testimonials from '../../../../../components/testimonials/Testimonials'


class SladuckeOvocie extends Component {

  render() {
    const { data } = this.props
    const { banner_url, screens } = data.project.frontmatter

    return (
      <Layout showBackButton={true}>


        <Landing
          fixedTitleWidth={true}
          disableMaxHeight={true}
          title={'Tvorba eshopu <br/>Sladučké ovocie'}
          description={"Prípadová štúdia"}
          description2="Svieži nový eshop s výstižným logom a brandingom pre obľúbeného slovenského predajcu čerstvých potravín."
          text={'Klientovi sme predstavili 3 firemné princípy, ktoré sme na dizajn webu aplikovali. Sú nimi prehľadnosť, kvalitné “šťavnaté” produktové fotografie a chutné texty opisujúce tovar. Slová informujú, emócie však predávajú.'}
          img={data.landingImage}
          pageMode={true}
          noShadow={true}
          what_we_done={[
            { text: 'UX & web design' },
            { text: 'kódovanie front-endu (bootstrap)' },
            { text: 'logo & branding' },
            { text: 'kreslené postavičky' },
            { text: 'programovanie (php+javascript)' }
          ]} />

        <AboutProject
          title="Úspešný eshop nevznikne sám od seba"
          text={'Vytvorenie eshopu založenom na novej brand identite si vyžaduje profesionálny prístup.'}
          list={[
            {
              title: 'Uchopenie projektu',
              text: 'Klient nás oslovil, pretože hľadal chýbajúci dielik pre úspešný biznis - kvalitný <a class="blue-link" href="/eshop-na-mieru">eshop</a> s pokročilými funkciami v originálnom štýle vďaka novej identite a logu, ktoré si zákazníci ľahko zapamätajú. Najskôr sme si s klientom prešli všetky požiadavky, aby sme <b>pochopili jeho očakávania</b> a spracovali projektovú dokumentáciu, ktorá by ich reálne naplnila.',
            },
            {
              title: 'Návrh riešenia',
              text: 'Úloha bola jasná. Vytvoriť oku lahodiaci moderný eshop s <a class="blue-link" href="/ux-ui-dizajn">intuitívnym UX</a> rozhraním a sviežim dizajnom, ktorý zaujme cieľovú skupinu. Musí byť už na prvý klik zrejmé, o aký eshop ide, a zároveň mať najlepšiu technologickú úroveň <b>funkcií, rýchlosti a SEO</b>. V rámci úzkej spolupráce sme s klientom diskutovali, ako tieto plány zrealizovať.',
            },
            {
              title: 'Priebeh vývoja',
              text: 'Dizajnér mal voľnú ruku, popustil uzdu kreativite a vytvoril originálny štýl/moodboard. Ďalej sa navrhli varianty loga a celá <a href="/branding" class="blue-link">korporátna identita</a>. Po schválení sme sa pustili do wireframov (rozloženia stránok) a finálneho  <a href="/webdizajn" class="blue-link">webdizajnu</a>. Pravidelne sme klientovi prezentovali výstupy <b>a diskutovali o nich</b>. Projekt sme finálne zverili do rúk našich programátorov.',
            },
            {
              title: 'Úspešný výsledok',
              text: 'Pred samotným spustením web optimalizujeme pre prehliadače, responzívnosť a SEO, pre čo najlepšie pozície na Googli. Odovzdaním webu spolupráca s klientom nekončí a projekt vylepšujeme ďalej. Sledujeme správanie návštevníkov cez Hotjar a Google Analytics a na základe získaných dát klientom radíme <b>ako zvýšiť ich tržby</b>.',
            }
          ]}
        />

        <ProjectShortDescription
          fixedTitleWidth={true}
          title={'Tvorba brandu'}
          description={'Predpokladom plnohodnotnej firemnej identity je pochopenie projektu a jeho zámeru. Návrh loga prichádza potom.'}
          text={'Po konzultáciách s klientom sme pripravili zrozumiteľné firemné princípy, ktoré by mal projekt prezentovať. Následne sme odkomunikovali základné piliere toho, ako sa <b>odlíšiť od konkurencie</b> a cez nový branding značky ukázať jedinečnú esenciu firmy.'} />

        <Reveal>
          <div className={'container p-0 mb-4'}>
            <Img alt={this.constructor.name} fluid={data.logos.childImageSharp.fluid} />
          </div>
        </Reveal>

        <ColorShowcase colors={[
          { code: '#2C8D56', tintColorCode: '#56a478', nameColor: '#FFFFFF', name: 'Zelená' },
          { code: '#6654CA', tintColorCode: '#8576d5', nameColor: '#FFFFFF', name: 'Fialová' },
          { code: '#ff7e00', tintColorCode: '#f5bb41', nameColor: '#FFFFFF', name: 'Jantárová ' },
        ]} />

        <FontShowcase
          fontName={'Nunito'}
          text={'Nunito je dobre vybalansovaná rodina písma typu sans serif (bezpätkové), ktorá existuje v dvoch verziách: Písmo začalo vo verzii Nunito, ktoré vytvoril Vernon Adams ako zaokrúhlený terminal sans serif pre typografiu na diplejoch. Jacques Le Bailly ho rozšíril o celú radu rezov spolu so sprievodnou regular verziou, ktorá nie je zaoblená a nazýva sa Nunito Sans.'}
          isFullWidth={false}
          titleSize={'4em'}
          fontExampleSvg={'/images/svg/fonts/nunito.svg'}
        />

        <div className='full-width-image'>
          <Img fluid={data.vegetables.childImageSharp.fluid} alt='vegetables' />
        </div>

        <PagesShowcase
          title={'Webový dizajn na mieru'}
          description={'Profesionálny eshop sa bez neho nezaobíde.'}
          text={'Moderný dizajn webu a pokročilé funkcie administrácie sú základ, ktorý by mal najlepší eshop systém spĺňať. Avšak aj perfektná responzivita, intuitívne používateľské rozhranie a UX prístup sú atribúty, ktoré zákazník očakáva od internetového obchodu, do ktorého sa bude pravidelne vracať.'}
          projectDetail={'/nase-prace/webstranky/eshopy/sladucke-ovocie'}//TODO
          pages={data.pages.nodes.map(node => ({ image: node, name: removeNumberPrefix(node.name) }))}
          fixedTitleWidth={true}
        />

        {/* All images */}
        <Reveal>
          <div className='container mt-5 mb-5 pt-5 pb-5 casestudy-lightbox'>
            <ProjectImageLightBox bannerUrl={banner_url} screens={screens} title="sladuckeovocie" />
          </div>
        </Reveal>

        <MobileCarousel
          description='Väčšina návštevníkov webstránky prichádza cez mobily. Myslíme na to.'
          items={data.mobileGallery.nodes}
          className="green"
          smallDescription="Vytvoriť responzívny web do posledného detailu je kľúčové, pretože určuje, či bude <a href='/ux-ui-dizajn' class='blue-link'>UX (user experience)</a> pre návštevníkov jednoducho použiteľné. Pri projektoch vždy dbáme, aby boli prehľadné a intuitívne na smartfónoch (mobile first), tabletoch a ďalších rozlíšeniach. <br/><br/>Aj vďaka tomu sa zvyšuje šanca, že sa z návštevníkov stanú zákazníci, využijú služby, zakúpia produkt či vykonajú inú požadovanú akciu bez akýchkoľvek prekážok. Toto by mala byť priorita každého, kto má vlastný eshop a chce, aby mu prinášal čo najväčší zisk."
        />

        {/* Testimonial */}
         <Reveal otherClassName="mt-5">
              <Testimonials
                  references={data.saidAboutUs.childMarkdownRemark.frontmatter.references.filter(r => r.author.search('Flipwork') > -1)}
                  addContainer={true}
                  enableSaidAboutUs={false}
                  sliderClassName='my-0'
                  className="pb-0"
              />
          </Reveal>

        {/* <ClientReference
          text={'Nara.design sme kontaktovali na redizajn nášho portálu hypoteka.sk ktorý už bol niekoľko rokov zastaralý. Po niekoľkých stretnutiach prišli dizajnéry s desiatkami vylepšení a skrášlení, ktoré sme ako jeden z najnavštevovanejších portálov potrebovali.'}
          clientName={'David Kopecký'}
          clientRole={'Majiteľ Allriskslovakia'}
          clientPhoto={data.clientPhoto}
        /> */}

        <div className="extern-page-link">
          <a rel="noreferrer" href="https://www.sladuckeovocie.sk" target='_blank' className='link-secondary'>www.sladuckeovocie.sk</a>
        </div>

        <Footer
          title={'OurServices.Process'}
          subtitle={'OurServices.ProcessSubTitle'}
          link={'/o-nas/ako-pracujeme'}
          leftTopTitle='project'
          nextProjectName={'Smartwear'}
          nextProjectUrl={'/nase-prace/pripadove-studie/webstranky/eshopy/smartwear/'}
        />
      </Layout>
    )
  }
}

export const query = graphql`{
  project: markdownRemark(fields: {slug: {regex: "/sladuckeovocie/"}}) {
      frontmatter {
        banner_url
          screens {
              href {
                  childImageSharp {
                      fluid(quality: 100, maxWidth: 1920) {
                          ...GatsbyImageSharpFluid_withWebp
                          presentationWidth
                      }
                  }
              }
              alt
          }
      }
  }
  landingImage: file(relativePath: { eq: "case-studies/sladucke-ovocie/visual.png" }) {
      childImageSharp {
          fluid( maxWidth: 1600) {
              ...GatsbyImageSharpFluid_withWebp
          }
      }
  }
  vegetables: file(relativePath: { eq: "case-studies/sladucke-ovocie/vegetables.png" }) {
      childImageSharp {
          fluid( maxWidth: 2000) {
              ...GatsbyImageSharpFluid_withWebp
          }
      }
  }
  pages: allFile(filter: { relativePath: {regex: "/(case-studies/sladucke-ovocie/pages/)/"} }, sort: {fields:name}) {
      nodes {
          name
          childImageSharp {
              fluid( maxWidth: 1600) {
                  ...GatsbyImageSharpFluid_withWebp
              }
          }
      }
  }
  mobileGallery: allFile(filter: { relativePath: {regex: "/(case-studies/sladucke-ovocie/mobileGallery/)/"} }, sort: {fields:name}) {
      nodes {
          childImageSharp {
              fluid( maxWidth: 320) {
                  ...GatsbyImageSharpFluid_withWebp
              }
          }
      }
  }
  logos: file(relativePath: { eq: "case-studies/sladucke-ovocie/logos.png" }) {
    childImageSharp {
        fluid( maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
        }
    }
  }
  saidAboutUs: file(relativePath: {eq: "pages/sk/homepage/said_about_us.md"}) {
      childMarkdownRemark {
          frontmatter {
              text_paragraph
              references {
                  description
                  logo: logo {
                      childImageSharp {
                          fluid(maxWidth: 200) {
                              ...GatsbyImageSharpFluid_withWebp
                          }
                      }
                  }
                  logoBig: logo {
                      childImageSharp {
                          fluid(maxWidth: 200) {
                              ...GatsbyImageSharpFluid_withWebp
                          }
                      }
                  }
                  author
              }
          }
      }
  }
}
`

export default SladuckeOvocie
