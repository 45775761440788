import React, { Component } from 'react'
import { TranslatedLink } from '../TranslatedLink'
import Img from 'gatsby-image'
import { getTranslateFunction, removeHtml } from '../../utilities'
import PropTypes from 'prop-types'
import PageTitle from '../pageTitle'

class Landing extends Component {
  render() {
    const t = getTranslateFunction('sk')
    const { title, description, description2, text, what_we_done, img, pageMode, className, link, disableMaxHeight, fixedTitleWidth, url } = this.props
    let SEOdescription = description2
    if (!SEOdescription) {
      SEOdescription = text || description
    }

    return (
      <section className={`cs-landing detail-reference pt-5 mb-0 ${pageMode ? 'page-mode' : ''} ${disableMaxHeight ? 'disable-max-height' : ''} ${className}`}>
        <div className="container" style={{ position: pageMode ? 'unset' : undefined }}>
          <div className="row">
            <div className="col-lg-6 left">
              <div className={`${fixedTitleWidth ? 'fixWidth' : ''}`}>
                <PageTitle className="maxWidthIndicator" title={title} description={SEOdescription} image={img} url={url} />
                {link == null
                  ? <h2 className="description font-black" dangerouslySetInnerHTML={{ __html: description }}></h2>
                  : <a className="description link" href={link} target={'_blank '}>{description}</a>
                }
                <h2 className="description" dangerouslySetInnerHTML={{ __html: description2 }}></h2>
                <p className="description-smaller" dangerouslySetInnerHTML={{ __html: text }}></p>
                <h3>{t('CaseStudyDetail.Subtitle')}</h3>
              </div>
              <ul className="works">
                {what_we_done.map((item, i) => {
                  return item.link != null ?
                    (
                      <li className={'hvr-icon-pulse-shrink'} key={i}><img className="image" src="/images/svg/icon_tag.svg" alt={item.text}/>
                        <TranslatedLink to={item.link}>
                          {item.text}
                          <img className="image ml-1 hvr-icon" src="/images/svg/icon_link.svg" alt={item.text}/>
                        </TranslatedLink>
                       
                      </li>
                    ) :
                    (
                      <li key={i}><img className="image" src="/images/svg/icon_tag.svg" alt={item.text}/>{item.text}</li>
                    )
                })}
              </ul>
            </div>
            <div className="col-lg-6 page-img" style={{ position: pageMode ? 'unset' : undefined }}>
              <div className="img" style={{ boxShadow: this.props.noShadow === true ? 'none' : null }}>
                <Img fluid={img.childImageSharp.fluid} alt={title}/>
              </div>
            </div>
          </div>
        </div>
      </section>)
  }
}
Landing.defaultProps = {
  disableMaxHeight: false,
  fixedTitleWidth: false
}

Landing.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  what_we_done: PropTypes.array.isRequired,
  img: PropTypes.object.isRequired,
  noShadow: PropTypes.bool,
  disableMaxHeight: PropTypes.bool,
  url: PropTypes.string
}

export default Landing
