import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import Reveal from '../reveal'

class FontShowcase extends Component {
  render() {
    const { isFullWidth, fontName, text, fontExampleSvg, className } = this.props
    return (
      <Reveal>
        <div className={`cs-font-showcase mt-4 ${isFullWidth ? '' : 'container'} ${className}`}>
          <div className={'d-flex justify-content-center ' + (isFullWidth ? 'container' : '')}>
            <div className={'wrapper col-md-9'}>
              <h2 className={'col-md-10 pl-0'} style={{ fontSize: this.props.titleSize }}>
                {fontName}
              </h2>
              <div className={'row'}>
                <div className={'col-md-5'}>
                  <p dangerouslySetInnerHTML={{ __html: text }}>
                  </p>
                </div>
                <div className={'col-md-5 offset-md-2'}>
                  <img src={fontExampleSvg} alt={'Font example'} className={'alphabet'}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Reveal>
    )
  }
}

FontShowcase.propTypes = {
  isFullWidth: PropTypes.bool.isRequired,
  fontName: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  fontExampleSvg: PropTypes.string.isRequired,
}

export default FontShowcase