import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Tilt from 'react-tilt'
import Lightbox from 'react-images'
import LinkBtn from '../linkBtn/LinkBtn'
import { getTranslateFunction } from '../../utilities'
import Img from 'gatsby-image'
import './CaseStudies.scss'

class ProjectImageLightBox extends Component {
  constructor(props) {
    super(props)

    this.screensWrapperRef = React.createRef()
    this.contRef = React.createRef()

    this.state = {
      galleryCollapsed: true,
      currentLightBoxItem: 1,
      lightBoxOpened: false,
      defaultShowed: 8
    }

    this.handleExpandGalleryToggle = this.handleExpandGalleryToggle.bind(this)
    this.openLightBox = this.openLightBox.bind(this)
    this.closeLightBox = this.closeLightBox.bind(this)
    this.goToNextLightBox = this.goToNextLightBox.bind(this)
    this.goToPrevLightBox = this.goToPrevLightBox.bind(this)
  }


    handleExpandGalleryToggle() {
    this.setState((prevState) => {
      if (prevState.galleryCollapsed === false) {
        if (this.contRef != null) {
          this.contRef.current.scrollIntoView({ behavior: "auto", block: "start", inline: "start" });
          //   scrollIntoView(this.contRef.current, false)  
        }
      }
      return {
        galleryCollapsed: !prevState.galleryCollapsed,
      }
    })
  }
  openLightBox(i) {
    if (typeof document !== 'undefined') {
      setTimeout(() => {
        try {
          document.querySelector('#lightboxBackdrop').parentElement.parentElement.parentElement.classList.add('lightboxBackdrop-wrapper')
        } catch (e) {
          console.error(e)
        }
      }, 0)
    }
    this.setState({
      currentLightBoxItem: i,
      lightBoxOpened: true,
    })

    document.body.style.backgroundPosition = 'calc(100% - 12px) 0'

    setTimeout(() => {
      if (typeof document !== 'undefined') {
        const el = document.getElementById('lightboxBackdrop')
        if (el) {
          el.classList.add('fade')
        }
      }
    }, 1)
  }

  closeLightBox() {
    if (typeof document !== 'undefined') {
      const el = document.getElementById('lightboxBackdrop')
      if (el) {
        el.classList.remove('fade')
      }
    }
    setTimeout(() => {
      document.body.style.backgroundPosition = '100% 0'

      this.setState({
        lightBoxOpened: false,
      })
    }, 1)
  }

  goToNextLightBox() {
    document.getElementById('lightboxBackdrop').getElementsByTagName('img')[0].classList.add('fade-out')
    const newIndex = this.state.currentLightBoxItem + 1
    const len = this.props.screens.length
    setTimeout(() => {
      this.setState({
        currentLightBoxItem: newIndex < len ? newIndex : len - 1,
      })
      document.getElementById('lightboxBackdrop').getElementsByTagName('img')[0].classList.remove('fade-out')
    }, 1000)
  }

  goToPrevLightBox() {
    document.getElementById('lightboxBackdrop').getElementsByTagName('img')[0].classList.add('fade-out')
    const newIndex = this.state.currentLightBoxItem - 1
    //  const len = this.props.screens.length
    setTimeout(() => {
      this.setState({
        currentLightBoxItem: newIndex > 0 ? newIndex : 0,
      })
      document.getElementById('lightboxBackdrop').getElementsByTagName('img')[0].classList.remove('fade-out')
    }, 1000)
  }

  onGalleryClick(e) {
    if (!e.target.closest('img') && !e.target.closest('svg')) {
      console.log(!e.target.closest('svg'))
    }
  }


  render() {
    const { bannerUrl, screens, title, sectionTitle } = this.props
    const { defaultShowed } = this.state
    const t = getTranslateFunction('sk')
    const useIframeView = bannerUrl?.trim().length > 0;

    let collapseToggleElement = this.state.galleryCollapsed ? (
      <LinkBtn handleClick={this.handleExpandGalleryToggle}>
        <span >{t('CaseStudyDetail.GalleryShowMore')}</span>
      </LinkBtn>
    ) : (
      <LinkBtn handleClick={this.handleExpandGalleryToggle} color={'silver'}>
        <span >{t('CaseStudyDetail.GalleryShowLess')}</span>
      </LinkBtn>
    )

    if (screens?.length <= defaultShowed) {
      collapseToggleElement = null
    }
    let height = 0;
    if (screens) {
      height = screens.length / 4 * 240 
    }
    if (typeof document !== 'undefined') {
      let item = document.getElementById('box-inner')
      if (item) {
        height = item.offsetHeight
      }
    }

    return (
      <div className='projectImageLightBox row cs-projectImageLightBox' ref={this.contRef}>
        <div className="col-12">
          <h2 className="text-center mb-5">{sectionTitle}</h2>
        </div>
        {useIframeView
          ? <div className="right banner">
            <iframe title="bannerPreview" src={bannerUrl} width={304} height={604} scrolling={false} id={'bannerPreview'} />
          </div>
          : <div className="col-md-12 right dr-gallery-wrap">
            <div
              className={`mb-4 dr-gallery ${this.state.galleryCollapsed ? '' : 'open'}`}
              style={{ height: height + 'px' }}
              ref={this.screensWrapperRef}
            >
              <div className="row" id="box-inner">
                <Lightbox
                  images={screens.filter((i) => {
                    return i.href != null
                  }).map((i, n) => {
                    return {
                      src: i.href.childImageSharp.fluid.srcWebp,
                      width: i.href.childImageSharp.fluid.presentationWidth,
                      alt: `${title} #${n}`
                    }
                  })}
                  onClose={this.closeLightBox}
                  isOpen={this.state.lightBoxOpened}
                  onClickPrev={this.goToPrevLightBox}
                  onClickNext={this.goToNextLightBox}
                  preloadNextImage={false}
                  currentImage={this.state.currentLightBoxItem}
                  closeButtonTitle={'Zatvoriť'}
                  imageCountSeparator={' z '}
                  leftArrowTitle={'Predchádzajúce'}
                  rightArrowTitle={'Ďalšie'}
                  backdropClosesModal={true}
                  preventScroll={false}
                />

                {screens.map((screen, i) => {
                  let shouldRender = (i < defaultShowed || !this.state.galleryCollapsed)
                  let shouldHide = i < defaultShowed
                  return (
                    <div className={'cp col-sm-6 col-lg-3 mb-4 item ' + (shouldHide ? '' : (shouldRender ? 'visible' : 'no-visible'))}
                      key={i} onClick={() => {
                        this.openLightBox(i)
                      }}>
                      <Tilt
                        className={'tilt'}
                        options={{
                          max: 25,
                          glare: true,
                          'max-glare': 0.5,
                          reverse: true,
                          scale: 1.05,
                        }}>
                        {screen.href != null && <Img
                          style={{ height: 223, maxHeight: 223, maxWidth: 223 }}
                          fluid={screen.href.childImageSharp.fluid}
                          alt={screen.alt}
                          imgStyle={{ objectPosition: 'center top' }}
                        />}
                      </Tilt>
                    </div>
                  )
                })
                }
              </div>
            </div>
            {collapseToggleElement}
          </div>
        }
      </div>
    )
  }
}

ProjectImageLightBox.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

ProjectImageLightBox.defaultProps = {
  fixedTitleWidth: false,
  sectionTitle: 'Viac podstránok'
}


export default ProjectImageLightBox